.scroller.child_area {
  padding-left: 5px;
  padding-bottom: 5px;
  padding-right: 5px;
  background: rgba(0, 0, 0, 0.15);
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;

  overflow-x: hidden;
  overflow-y: auto;
}